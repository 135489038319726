<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';

export default {
  locales: {
    pt: {
      'New Contract': 'Novo Contrato',
      'Request': 'Solicitar',
      'Value': 'Valor',
    },
    es: {
      'New Contract': 'Nuevo Contrato',
      'Request': 'Solicitar',
      'Value': 'Valor',
    }
  },
  components: { Layout },
  data() {
    return {
      loading: false,
      success: false,

      alert: {
        type: '',
        message: '',
        params: [],
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },

      contract: {
        value: 'R$ 0,00'
      }
    };
  },
  directives: { money: VMoney },
  validations: {
    contract: {
      value: { required },
    }
  },
  methods: {
    requestContract: function () {
      this.loading = true
      this.$v.contract.$touch();

      if (this.contract.value) {
        api
          .post('contract', {
            value: this.contract.value,
          })
          .catch(error => {
            if (error) {
              this.loading = false
            }
          })
          .then(response => {
            if (response.data.status=='success') {
              this.success = true
              this.value = ''
              this.$v.$reset()

              this.$router.push('/contracts/'+response.data.contract.id);
            } else {
              this.alert.type = 'alert-danger'
              this.alert.message = response.data.message
              if (response.data.params) {
                this.alert.params = response.data.params
              } else {
                this.alert.params = []
              }

              this.$v.$reset()
            }

            this.loading = false
          })
      }
    }
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('New Contract') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="success" class="text-center-sm">
              {{ t('Seu novo contrato foi solicitado com sucesso.') }}
            </div>
            <b-form v-else class="p-3" @submit.prevent="requestContract">
              <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
              <b-form-group id="value" :label="t('Value')" label-for="value">
                <b-input-group>
                  <b-form-input id="value" size="lg" v-model="contract.value" v-money="money" type="text" inputmode="decimal" class="rounded" :class="{ 'is-invalid': $v.contract.value.$error }"></b-form-input>
                </b-input-group>
                <div v-if="$v.contract.value.$error" class="text-danger font-size-12">
                  <span v-if="!$v.contract.value.required">{{ t('Digite o valor.') }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="loading == true || this.contract.value==='R$ 0,00'" size="lg" type="submit" variant="default">
                  {{ t('Request') }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
